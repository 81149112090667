import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { ListView } from './view';

export const List = () => {
    const data = useStaticQuery(graphql`
        query GetCatalogsList {
            allFolders(filter: {
                id: {
                    ne: "dummy"
                }
            }) {
                nodes {
                    name
                    description
                    brand_color
                    thumbnail_img
                    alt_thumbnail_img
                    folder_file
                }
            }
        }
    `);

    return (<ListView catalogs={ data?.allFolders?.nodes } />);
};