import React from 'react';

import { Navigation } from '../utilities/components/navigation/component';
import { List } from '../features/catalogs/list/component';
import { Footer } from '../utilities/components/footer/component';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import SEO from 'utilities/components/seo';

import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { routes } from 'utilities/routes';

import '../features/catalogs/view.scss';
import { useSeoTexts } from 'utilities/hooks/useSeoTexts';
import { SeoDecription } from 'features/index/seo-description/view';

const Catalogs = () => {
    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };

    const breadcrumbs = [routes.home, routes.katalogi];
    const { metaTitle, metaDescription, description } = useSeoTexts('Katalogi');

    return (
        <main className="catalogs">
            <SEO
                title={ metaTitle || 'Barański Drzwi - Katalogi Smart, Optimo, Premium zewnętrzne, wewnętrzne' }
                description={
                    metaDescription
                    || 'Przejrzyj katalogi drzwi wewnętrznych, drzwi wewnętrznych, drzwi klatkowych i technicznych, drzwi drewnianych.'
                }
                canonicalLink={ setCanonicalLink() }
            />
            <Navigation />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <List />
            <SeoDecription seoDescription={ description } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default Catalogs;
