import React from 'react';
import PropTypes from 'prop-types';

import { ButtonView } from 'utilities/components/button/component/view';
import { Img } from 'utilities/components/img/component';

import './view.scss';

export const ListView = ({ catalogs }) => (
    <section className="list">
        <h1 className="list__headline">
            Katalogi produktów
        </h1>
        {
            catalogs.map((currentValue, index) => (
                <div className="list__catalog" key={ index }>
                    <Img className="catalog__image" src={ `${process.env.GATSBY_URL}/${currentValue.thumbnail_img}` } alt={ currentValue.alt_thumbnail_img } />
                    <h4 className="catalog__headline" style={{ color: currentValue.brand_color }}>{ currentValue.name }</h4>
                    <p className="catalog__description">{ currentValue.description }</p>
                    <ButtonView text="Pobierz katalog" link={ `${process.env.GATSBY_URL}/${currentValue.folder_file}` } download target="_blank" />
                </div>
            ))
        }
    </section>
);

ListView.propTypes = {
    catalogs: PropTypes.instanceOf(Array).isRequired
};